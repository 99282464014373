<template>
  <main class="main page-inside text-page">
    <div class="section text-section">
      <div class="page-inside-top">
        <Transition>
          <div
            v-if="textPageTitle"
            class="wrapper"
          >
            <div class="title">
              {{ textPageTitle }}
            </div>
            <div
              class="desc"
              v-html="textPageHeader"
            />
            <div
              class="desc"
              v-html="textPageContent"
            />
          </div>
        </Transition>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'TextPage',
  components: {

  },
  props: {
    textPageTitle: {
      type: String,
      required: true
    },
    textPageHeader: {
      type: String,
      required: true
    },
    textPageContent: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  mounted() {
    this.$emit('getTextPageContent', this.$route.params.id);
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.text-page {
  padding: 60px;
}

.text-page .text-section a {
  color: #fff;
}

.text-page p {
  margin-bottom: 15px;
}

.text-page p strong {
  font-weight: bold;
  font-size: 18px;
}

.text-page p:last-child {
  margin-bottom: 0px;
}

.text-page ul {
  list-style: disc;
  padding-left: 30px;
}

@media (max-width: 768px) {
  .text-page {
    padding: 24px;
  }
}
</style>