<template>
    <div class="personal-information">
        <div class="info-row">
            <div class="header-row">
                <h2>{{ $t('PERSONAL INFORMATION') }}</h2>
                <button
                    class="save-changes-button"
                    @click="updateProfile"
                    :disabled="!phoneFull"
                >
                    {{ $t(`Save changes`) }}
                </button>
            </div>
            <div class="info-content">
                <div class="left-side">
                    <div class="input-group">
                        <input
                            type="text"
                            id="name"
                            v-model="name"
                            :placeholder="$t('First name')"
                        />
                    </div>
                    <div class="input-group">
                        <input
                            type="text"
                            id="surname"
                            v-model="surname"
                            :placeholder="$t('Last name')"
                        />
                    </div>
                    <div class="input-group">
                        <input
                            type="email"
                            id="email"
                            v-model="email"
                            :placeholder="$t('Email')"
                        />
                    </div>
                    <div class="input-group">
                        <MazPhoneNumberInput
                            noSearch
                            v-model="phone"
                            v-model:country-code="countryCode"
                            :placeholder="$t('Phone')"
                            show-code-on-list
                            :only-countries="countryOptionsIso"
                            @update="phoneDataUpdate($event)"
                        />
                    </div>
                </div>
                <div class="separator"></div>
                <div class="right-side">
                    <div class="input-group">
                        <input
                            type="text"
                            id="state"
                            v-model="city"
                            :placeholder="$t('State')"
                        />
                    </div>
                    <div class="input-group country-selector">
                        <select
                            id="country"
                            v-model="country"
                            :class="{ placeholder: !country }"
                        >
                            <option
                                value=""
                                disabled
                                selected
                            >
                                {{ $t('Country') }}
                            </option>
                            <option
                                v-for="country in countryOptions"
                                :key="country.id"
                                :value="country.id"
                            >
                                {{ country.title }}
                            </option>
                        </select>
                        <button
                            v-if="country"
                            class="delete-icon"
                            @click="clearCountry"
                        >
                            <img
                                src="@/assets/img/close.svg"
                                alt="Delete"
                            >
                        </button>
                    </div>
                    <div class="input-group">
                        <input
                            type="text"
                            id="address"
                            v-model="address"
                            :placeholder="$t('Address')"
                        />
                    </div>
                    <div class="input-group">
                        <input
                            type="text"
                            id="postCode"
                            v-model="zip"
                            :placeholder="$t('ZIP-code')"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="horizontal-separator"></div>

        <div class="password-row">
            <div class="header-row">
                <h2>{{ $t('CHANGE PASSWORD') }}</h2>
                <button
                    class="save-changes-button"
                    @click="updatePassword"
                >
                    {{ $t(`Save changes`) }}
                </button>

            </div>
            <div class="password-content">
                <div class="input-group">
                    <input
                        type="password"
                        id="currentPassword"
                        v-model="currentPassword"
                        :placeholder="$t('Current password')"
                    />
                </div>
                <div class="input-group">
                    <input
                        type="password"
                        id="newPassword"
                        v-model="newPassword"
                        :placeholder="$t('New password')"
                    />
                </div>
                <div class="input-group">
                    <input
                        type="password"
                        id="confirmPassword"
                        v-model="confirmPassword"
                        :placeholder="$t('Confirm new password')"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="infoError"
            class="error-message"
        >{{ infoError }}</div>
        <div
            v-if="infoSuccess"
            class="success-message"
        >{{ infoSuccess }}</div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'PersonalInformation',
    data() {
        return {
            name: '',
            surname: '',
            email: '',
            phone: '',
            phoneFull: '',
            phoneE164: '',
            city: '',
            country: '',
            address: '',
            zip: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            countryCode: '',
            countryOptions: [],
            infoError: null,
            infoSuccess: null
        };
    },
    mounted() {
        this.initializeData();
    },
    computed: {
        ...mapState('user', ['userData'])
    },
    watch: {
        userData() {
            this.initializeData();
        }
    },
    methods: {
        ...mapActions('user', ['updateUserProfile', 'fetchUserData']),
        phoneDataUpdate(e) {
            if (e.isValid) {
                this.countryCode = e.countryCallingCode || '';
                this.phone = e.nationalNumber || '';
                this.phoneFull = this.countryCode + ' ' + (e.nationalNumber || e.phoneNumber);
                this.phoneE164 = e.e164 || '';
            } else {
                this.phoneFull = '';
                console.error('Invalid phone number');
            }
        },
        getCountries() {
            this.$http.get(process.env.VUE_APP_API + 'countries')
                .then((res) => {
                    this.countryOptions = res.data.payload
                    this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
                    if (!this.countryCode) {
                        this.countryCode = this.countryOptionsIso[0];
                    }
                })
                .catch(() => {
                })
        },
        clearCountry() {
            this.country = '';
        },
        initializeData() {
            this.name = this.userData.name;
            this.surname = this.userData.surname;
            this.email = this.userData.email;
            this.phone = this.userData.phone;
            this.countryCode = this.userData.phoneCountryCode;
            this.city = this.userData.city;
            this.country = this.userData.country ?? '';
            this.address = this.userData.address;
            this.zip = this.userData.zip;
        },
        async updateProfile() {
            const personalInfo = {
                name: this.name,
                surname: this.surname,
                email: this.email,
                phone: this.phoneFull,
                phoneE164: this.phoneE164,
                country: this.country,
                city: this.city,
                postCode: this.zip,
                address: this.address
            }
            this.$http.put(process.env.VUE_APP_API + 'user/profile', personalInfo).then(response => {
                if (response.data?.status === "OK") {
                    this.$toast.success("Personal information successfully updated!");
                    this.personalInfoError = "";
                    this.fetchUserData();

                    setTimeout(() => {
                        this.personalInfoSuccess = ""
                    }, 2000);
                    // this.$toast.success("Personal information updated successfully!");

                } else {
                    this.personalInfoSuccess = "";
                    this.$toast.error(response.data.message || "An unexpected error occurred.");
                    setTimeout(() => {
                        this.personalInfoError = ""
                    }, 2000);
                }
            })
                .catch(error => {
                    this.personalInfoError = error.response.data.message || "Error updating personal information.";
                    setTimeout(() => {
                        this.personalInfoError = ""
                    }, 2000);
                    console.error('Error updating personal information:', error);
                });

        },
        clearForm() {
            this.currentPassword = '';
            this.newPassword = '';
            this.confirmPassword = '';
        },
        async updatePassword() {
            try {
                const data = {
                    cur_password: this.currentPassword,
                    new_password: this.newPassword,
                    repeat_password: this.confirmPassword
                };
                const response = await this.$http.post(process.env.VUE_APP_API + 'user/change-password', data);
                if (response.data.status === 'OK') {
                    this.$toast.success('Password changed successfully!');
                    this.clearForm();
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    this.$toast.error(error.response.data.message || 'An unexpected error occurred.');
                }
            }
        },
        async fetchCountryOptions() {
            try {
                const response = await this.$http.get(process.env.VUE_APP_API + 'countries');
                this.countryOptions = response.data.payload;
            } catch (error) {
                console.error('Error fetching country options:', error);
            }
        }
    },
    created() {
        this.fetchCountryOptions();
    }
};
</script>

<style lang="scss" scoped>
.personal-information {
    display: flex;
    flex-direction: column;
    padding: 38px 56px 49px;
    // gap: 42px;
}

.info-row,
.password-row {
    border-radius: 2px;
}

.password-row input {
    max-width: 340px;
}

.header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    gap: 10px;
}

.info-content {
    display: flex;
    justify-content: space-between;
}

.left-side,
.right-side {
    max-width: 340px;
    width: 100%;
}

.separator {
    width: 1px;
    height: 218px;
    background: #262634;
    margin: 0 10px;
}

.horizontal-separator {
    height: 1px;
    background: #262634;
    margin: 42px 0;
}

h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: white;
}

.input-group:not(:last-child) {
    margin-bottom: 14px;
}

.input-group.country-selector {
    position: relative;

    .delete-icon {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    .delete-icon img {
        width: 12px;
        height: 12px;
    }
}

select,
input {}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

.success-message {
    color: green;
    font-weight: bold;
    margin-top: 10px;
}

.header-row .save-changes-button {
    max-width: 226px;
    background: transparent;
}

@media (max-width: 1200px) {
    .info-content {
        // flex-direction: column;
        gap: 10px;
    }

    .horizontal-separator {
        margin: 20px 0;
    }

    .left-side,
    .right-side {
        max-width: 100%;
    }

    .password-row input {
        max-width: 100%;
    }

    .separator {
        // display: none;
    }
}

@media (max-width: 768px) {
    .personal-information {
        padding: 20px;
    }

    .info-row,
    .password-row {
        padding: 20px;
    }

    h2 {
        font-size: 14px;
    }

    .save-changes-button {
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .info-content {
        flex-direction: column;
        // gap: 10px;
    }

    .separator {
        // transform: rotate(90deg);
        margin: 0;
        height: 1px;
        width: 100%;
        // display: none;
    }
}
</style>